[data-letters]:before {
    content:attr(data-letters);
    display:inline-block;
    font-size:1em;
    font-weight: bold;
    width:2.5em;
    height:2.5em;
    line-height:2.5em;
    text-align:center;
    border-radius:50%;
    background:#E6EBF1;
    vertical-align:middle;
    margin-right:1em;
    color:#586879;
}

.uploadedFile {
	padding: 0px 1rem 1rem 1rem;
	font-size: 16px;
}

.deleteAttachment:hover {
	cursor: pointer;
	color: red;
}

.messages {
    overflow-y: auto;
    height: 70vh;
   
}
