#complianceDetails .buttons {
    display: flex;
    justify-content: center;
}

#complianceDetails .buttons button{
    margin-right: 1rem;
}

#form .sectionHeader {
    margin-top: 2rem;
    margin-bottom: 0;
}

#form .groupHeader {
    margin-top: 1rem;
}

#form .withoutPadding {
    padding-top: 0;
    padding-bottom: 0;
}