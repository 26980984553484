.exchange-order-details {
	padding-bottom: 1rem !important;
}

.exchange-order-details .field-label {
	font-weight: bold;
}


.exchange-order-details .field-label {
	font-weight: bold;
}

.exchange-order-details .data-warning {
	color: #D62424;
	font-weight: bold;
	font-size: 0.875rem;

}