#navbar.ui.menu {
    margin-bottom: 1em;
}

#navbar.ui.menu .active.item.logo {
    background-color: #ffffff;
}

#navbar.ui.menu .active.item.logo:hover {
    background-color: #ffffff;
}

#navbar.ui.menu .active.item.navbaritem {
    background: none;
}

#navbar.ui.menu .item.navbaritem svg {
    margin-right: 6px;
}

#navbar.ui.menu .item>i.icon:hover {
    cursor: pointer;
}

svg:not(:root) .mobileNavbarIcon {
    display: none;
}

.ui.button.mobileNavbarBTN {
    display: none;
}

svg.mobileLogo:not(:root) {
    display: none;
}
