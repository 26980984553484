.break-cell-content.ui.grid.celled {
	margin: 0;
}

#balance-tree .grid-custom-headers div {
	font-weight: bold;
}

#balance-tree .break-cell-content div {
	white-space: normal;
	word-break: break-word;
}

#balance-tree .ui.styled.accordion,
#balance-tree .balance-content .accordion.ui.styled.fluid {
	box-shadow: none;
}


#balance-tree .balance-content {
	padding-left: 0;
	padding-right: 0;
}

  #balance-tree .balance-accordion{
	padding-right: 0;
	padding-left: 0;
}  

 #balance-tree .balance-content .balance-row  {
	align-items: flex-start;
	width: inherit;
	margin: 0!important;
}  