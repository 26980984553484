#reconciliations .editable-cell:hover {
	outline-style: solid;
	outline-width: thin;
	border-radius: 5px;
}

#reconciliations .editable-cell textarea,
#reconciliations .editable-cell input {
	border: none;
	background: transparent;
}

#reconciliations .editable-cell .ui.search.selection.dropdown,
#reconciliations .editable-cell .ui.selection.dropdown {
	border: none;
	min-width: 100%;
	background: transparent;
}

#reconciliations .editable-cell textarea:focus,
#reconciliations .editable-cell input:focus {
	outline: none;
}

#reconciliations .table th,
#reconciliations .table td {
	white-space: normal;
	padding: 0.2rem;
	word-break: break-word; 
}


#reconciliations .reconcile-amount {
	width: 5rem;
	min-width: 3rem;
}

#reconciliations textarea {
	resize: none;
	text-align: center;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	width: 100%;
	/* 	transform: translate(0%, 30%); */
}