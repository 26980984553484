#filters {
	padding-left: 1rem;
}

#filters .field {
	margin-right: 1rem;
}

#filters .row {
	padding-bottom: 0px;
}

#filters .row#buttons {
	padding-top: 0px;
}

#table-layout {
	width: 98%;
	margin: 1rem;
}

#table {
	width: 100%;
	overflow-x: auto;
}

#table .totalPages {
	margin-left: 1rem;
}

#tablePagination {
	float: right;
}

#table th.sortable:not(.sorted):not(.sorted):before {
	font-family: "Icons";
	margin-right: .3em;
	content: '\f0dc';
	opacity: .2;
}

#table .itemsPerPage {
	margin-left: 5px;
}

#table td.cellTextLeft {
	text-align: left;
}

#table .pagination-column {
	width: auto !important;
}

#filters .ui.input.field input:required:invalid {
	border-color: red;
}