.assets{
	justify-content: space-around;
	flex-wrap: wrap;
	display: flex;
}
.assets__element {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding: 0 15px;
}
.assets__element .btn {
	cursor: pointer;
	border: 1px solid #AAB7C4;
	padding: 3px 5px;
	border-radius: 0.28571429rem;
}

.transfer {
	text-align: center;
}