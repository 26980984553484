#dashboard .app-layout {
    margin-bottom: 124px;
}

#dashboard .dashboardColumns {
    padding-left: 0px;
    padding-right: 0px;
}

#dashboard .ui.container {
       width: 98%;
} 
 

.ui.sidebar.visible ~ .pusher {
    width: calc(100% - 350px); 
} 

.ui.vertical.sidebar.menu > .item::before {
	z-index: 10;
}
/* show/hide sidebar style */
.ui.sidebar.visible ~ .pusher {
    width: calc(100% - 350px); 
} 

/* remove scroll bar on menu section*/
.ui.sidebar.vertical-menu {
	overflow-y: unset !important; 
}

/* full high sidebar menu - allow to overflow parent container*/
.ui.segment.pushable{
	overflow-x: unset;
}

 
/* end show/hide sidebar style */

@media only screen and (max-width: 767px) {
    #dashboard .ui.attached.tabular.menu {
        visibility: hidden;
    }
    #dashboard .ui.tabular.menu+.attached:not(.top).segment {
        margin-top: -50px;
    }
    #dashboard .ui.container {
        margin-left: 0em !important;
        margin-right: 0em !important;
    }
    #dashboard .ui.secondary.pointing.menu {
        margin-left: 1.5em;
        margin-right: 1.5em;
    }
/*     #dashboard {
        overflow-x: hidden;
    } */
    #dashboard .app-layout {
        margin-bottom: 0px;
    }
}